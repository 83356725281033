import {
  subDays,
  format,
  getUnixTime,
  endOfToday,
  startOfYesterday,
  startOfWeek,
  startOfDay,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addMonths,
  fromUnixTime,
} from 'date-fns';
import differenceBy from 'lodash/differenceBy';
import omit from 'lodash/omit';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { getName, parseMilliseconds } from '@uptime/shared/utils/general';
import { ALL_WEEK_DAYS, RUN_TASK_PERSISTED_FILTER, TASK_STATUSES } from '@uptime/shared/constants';
import { deserializeGroups, responseLens } from '@uptime/shared/utils/general';

import {
  IN_PROGRESS_TASKS,
  INIT_FILTERS,
  INITIAL_PAGINATION_CONFIG,
  HOME_PAGE_PAGINATION_CONFIG,
  TASK_FOR_AREA,
  TASK_FOR_DEVICE,
  TASK_BY_USER,
  TASK_BY_GROUP,
  TASK_TYPES,
  DUE_DATE_TYPES,
  TASK_TYPES_REVERSE,
} from '../constants/checkList';
import { getDaylightCorrection } from '@uptime/shared/utils/date';
import { alphabeticalSorting } from '@uptime/shared/utils/serviceProviders';
import { ORDER_ASC, ORDER_DESC } from '../components/List';
import { DEVICE_USE } from '../constants/device';

export const getDeviceOptions = (devices) =>
  responseLens(devices, 'hits', [])
    .map(({ id, assetId, model, make, category, facilityId, deviceUse }) => ({
      value: id,
      label: `${assetId} - ${make} - ${category} - ${model}${
        deviceUse === DEVICE_USE.INACTIVE ? ' (Inactive)' : ''
      }`,
      assetId,
      facilityId,
    }))
    .sort(alphabeticalSorting('label'));

export const getUserOptions = (users) => {
  return (
    users
      ?.map((item) => ({
        value: item.userId,
        label: getName(item),
        email: item.email || item.baseUser.email,
        ...item,
      }))
      .sort(alphabeticalSorting('label')) || []
  );
};

const addProgress = (currentTime, currentTask, isProgress) => (currentRunTask) => {
  const { startOn, scheduleTime } = currentRunTask;
  const correctedScheduleTime = startOn
    ? getDaylightCorrection(fromUnixTime(scheduleTime), fromUnixTime(startOn)).getTime()
    : parseMilliseconds(scheduleTime);
  const startTime = subDays(currentTime, 1);

  const progress =
    correctedScheduleTime <= currentTime || !isProgress
      ? 100
      : ((currentTime - startTime) * 100) / (correctedScheduleTime - startTime);

  return { ...currentRunTask, progress: progress > 0 ? progress : 0 };
};

export const transformRunTasks = (tasks, currentTime) => {
  if (!tasks) return [];

  return tasks.reduce((memo, currentTask) => {
    const runTaskComposer = addProgress(
      currentTime,
      currentTask.task,
      currentTask.status === TASK_STATUSES.in_progress
    );

    const composedRunTask = runTaskComposer(omit(currentTask, ['task']));

    memo.push({
      ...currentTask.task,
      ...composedRunTask,
      parentTaskId: currentTask.task.id,
    });

    return memo;
  }, []);
};

export const getTimeInSeconds = (dueDate, dueTime) => {
  const formattedDate = format(dueDate, 'MMMM d, y');
  const formattedTime = format(dueTime, 'HH:mm:ss');
  const dateString = `${formattedDate} ${formattedTime}`;
  const finalDate = new Date(dateString);

  return finalDate.getTime() / 1_000;
};

export const getStatusLabel = (status, progress) => {
  switch (status) {
    case 'incomplete': {
      return 'Did not Complete';
    }
    case 'complete': {
      return 'Complete';
    }
    default: {
      return progress < 100 ? 'To Do' : 'Overdue';
    }
  }
};

export const getUserSectionLabel = (status) => {
  switch (status) {
    case TASK_STATUSES.incomplete: {
      return 'Incompleted by';
    }
    case TASK_STATUSES.complete: {
      return 'Completed by';
    }
    default: {
      return 'Assigned to';
    }
  }
};

export const prepareTasksForUpdating = (initValues = {}, actualValues) => {
  const tasksToDelete = differenceBy(initValues.subTasks, actualValues.subTasks, 'id').map((item) => {
    return { ...omit(item, ['__typename']), operation: 'delete' };
  });

  const tasksToUpdate = (actualValues.subTasks || [])
    .map((item) => {
      const initItem = initValues.subTasks.find((initSubTask) => initSubTask.id === item.id);

      if (initItem && (initItem.name !== item.name || initItem.note !== item.note)) {
        return { ...omit(item, ['__typename']), operation: 'update' };
      }

      return omit(item, ['__typename']);
    })
    .filter((item) => item.operation === 'update');

  const lastPositionKey = !isEmpty(initValues.subTasks) ? last(initValues.subTasks).position : 0;

  const tasksToCreate = (actualValues.subTasks || [])
    .filter((item) => item.operation === 'create')
    .map((item, index) => ({ ...item, position: lastPositionKey + index + 1 }));

  return {
    ...actualValues,
    subTasks: [...tasksToUpdate, ...tasksToDelete, ...tasksToCreate],
  };
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const loadMore = (prev = {}, moreResult, name) => {
  if (!moreResult?.[name]?.hits?.length) {
    return prev;
  }

  const { hits: currentHits } = prev[name];
  const { hits: newHits, ...rest } = moreResult[name];

  const result = [...currentHits, ...newHits];
  const uniqueIds = Array.from(new Set(result.map((e) => e.id)));

  return Object.assign({}, prev, {
    [name]: {
      ...rest,
      hits: uniqueIds.map((id) => ({
        ...result.find((e) => e.id === id),
      })),
    },
  });
};

export const loadMoreRunTasks = (prev = {}, moreResult, name) => {
  if (!moreResult || moreResult[name]?.hits?.length === 0) return prev;

  const result = [...prev[name].hits, ...moreResult[name].hits];

  return Object.assign({}, prev, {
    [name]: {
      ...prev[name],
      hits: Array.from(new Set(result.map((e) => e.id))).map((id) => ({
        ...result.find((e) => e.id === id),
      })),
    },
  });
};

export const moveUpdatedTaskBetweenCaches = ({
  previousProxyCache,
  previousVariables,
  proxyCache,
  variables,
  updateTask,
}) => {
  const { tasks: previousTasks } = previousProxyCache;
  const { tasks } = proxyCache;

  const taskExistsInCache = previousTasks.hits.some((task) => task.id === updateTask.id);
  const previousItemsCount = taskExistsInCache ? previousTasks.itemsCount - 1 : previousTasks.itemsCount;
  const itemsCount = tasks.itemsCount + 1;

  return {
    updatedPreviousTasks: {
      tasks: {
        ...previousTasks,
        itemsCount: previousItemsCount,
        pageCount: Math.ceil(previousItemsCount / previousVariables.pagination.itemsAmount),
        hits: previousTasks.hits.filter((task) => task.id !== updateTask.id),
      },
    },
    updatedTasks: {
      tasks: {
        ...tasks,
        itemsCount,
        pageCount: Math.ceil(itemsCount / variables.pagination.itemsAmount),
        hits: [updateTask, ...tasks.hits],
      },
    },
  };
};

export const updateRunTasksCache = (proxyCache, runTaskId, subTaskData) => {
  const { isDone, note, shouldUpdateCounter } = subTaskData;

  // there is a case when cache for runTasks does not exist yet.
  // in this case we have nothing to update, so we can skip the update.
  if (!Boolean(proxyCache)) return;

  const { runTasks } = proxyCache;

  const indexOfTask = findIndex(runTasks.hits, (cachedTask) => cachedTask.id === runTaskId);

  if (indexOfTask < 0) return { runTasks };

  const counter = runTasks.hits[indexOfTask].runSubTasksCounter.done + (isDone ? 1 : -1);
  const done = shouldUpdateCounter ? counter : runTasks.hits[indexOfTask].runSubTasksCounter.done;

  const updatedTask = {
    ...runTasks.hits[indexOfTask],
    runSubTasksCounter: {
      ...runTasks.hits[indexOfTask].runSubTasksCounter,
      done,
      note,
    },
  };
  const updatedTasks = fill([...runTasks.hits], updatedTask, indexOfTask, indexOfTask + 1);

  return {
    runTasks: {
      ...runTasks,
      hits: updatedTasks,
    },
  };
};

export const removeTaskAndUpdateCache = (proxyCache, taskId) => {
  const {
    tasks: { hits, ...rest },
  } = proxyCache;

  const changedTasks = hits.filter((cachedTask) => cachedTask.id !== taskId);

  return {
    tasks: {
      ...rest,
      hits: changedTasks,
    },
  };
};

export const prepareFilters = (params) => {
  const filters = omit(params, ['due']);

  switch (params?.due) {
    case DUE_DATE_TYPES.yesterdayAndToday:
      filters.timeRange = {
        start: getUnixTime(startOfYesterday()),
        end: getUnixTime(endOfToday()),
      };
      break;
    case DUE_DATE_TYPES.thisWeek:
      filters.timeRange = {
        start: getUnixTime(startOfWeek(new Date())),
        end: getUnixTime(endOfWeek(new Date())),
      };
      break;
    case DUE_DATE_TYPES.thisAndNextMonth:
      const nextMonth = addMonths(new Date(), 1);
      filters.timeRange = {
        start: getUnixTime(startOfMonth(new Date())),
        end: getUnixTime(endOfMonth(nextMonth)),
      };
      break;
    case DUE_DATE_TYPES.pastThirtyDays:
      filters.timeRange = {
        start: getUnixTime(startOfDay(subDays(new Date(), 30))),
        end: getUnixTime(endOfToday()),
      };
      break;
    case DUE_DATE_TYPES.pastNinetyDays:
      filters.timeRange = {
        start: getUnixTime(startOfDay(subDays(new Date(), 90))),
        end: getUnixTime(endOfToday()),
      };
      break;
    default:
      break;
  }
  return filters;
};

export const getMutatedParamsForChecklistFilters = (params) => {
  const groups = params && params.groups ? params.groups : null;
  const isInvalid = params && params.isInvalid ? params.isInvalid : null;

  return {
    ...prepareFilters(params),
    groups,
    isInvalid,
  };
};

export const getTasksStatus = (isInProgress, isFirstColumn) => {
  const firstStatus = isInProgress ? 'in_progress' : 'complete';
  const secondStatus = isInProgress ? 'in_progress' : 'incomplete';
  return isFirstColumn ? firstStatus : secondStatus;
};

const getFilter = (search, isBuilder = false) => {
  try {
    const params = JSON.parse(decodeURIComponent(search.replace('?', '')));
    return prepareFilters(params);
  } catch (e) {
    return prepareFilters(isBuilder ? {} : INIT_FILTERS);
  }
};

export const normalizeFilters = (filters = {}) => {
  const groupForOmit = [
    Boolean(filters.assignedUserId),
    isEmpty(filters.groups),
    filters.groups === null,
  ].some((value) => value === true)
    ? ['groups']
    : [];
  const assignedUserForOmit = filters.groups || filters.assignedUserId === null ? ['assignedUserId'] : [];
  const facilityOmit = isEmpty(filters.facilityIds) ? ['facilityIds'] : [];
  const areaOmit = isEmpty(filters.areaIds) ? ['areaIds'] : [];
  const isInvalidOmit = filters.isInvalid ? [] : ['isInvalid'];

  return omit(filters, [
    ...groupForOmit,
    ...assignedUserForOmit,
    ...facilityOmit,
    ...areaOmit,
    ...isInvalidOmit,
    'type',
  ]);
};

export const getRunTaskFilterForBadges = () => {
  const persistedFilters = getPersistedFilters();
  const filters = prepareFilters({
    type: IN_PROGRESS_TASKS,
    due: DUE_DATE_TYPES.yesterdayAndToday,
    ...persistedFilters,
  });

  return normalizeFilters(filters);
};

const getPersistedFilters = () => {
  try {
    const persistedFilters = localStorage.getItem(RUN_TASK_PERSISTED_FILTER);
    return JSON.parse(persistedFilters);
  } catch {
    return {};
  }
};

export const getTasksVariables = (props, taskType) => {
  const persistedFilters = getPersistedFilters();
  const filters = getFilter(props.location.search, true);
  const filter = normalizeFilters({
    ...omit(persistedFilters, ['due']),
    ...omit(filters, 'search'),
    taskType,
  });

  return {
    userId: props.context.accountId,
    pagination: INITIAL_PAGINATION_CONFIG,
    search: filters?.search,
    filter,
  };
};

export const getBulkUpdateFilters = (search) => {
  const persistedFilters = getPersistedFilters();
  const filters = getFilter(search, true);
  const filter = normalizeFilters({
    ...omit(persistedFilters, ['due']),
    ...omit(filters, 'search'),
  });

  return {
    search: filters?.search,
    filter,
  };
};

export const getVariablesForChecklistRun = (props, requestInfo) => {
  const isHomePage = props.isHomePage;
  const { forOwner, isFirstColumn } = requestInfo;
  const filters = getFilter(props.location?.search);

  const persistedData = getPersistedFilters();
  const persistedFilters = prepareFilters({
    type: filters.type,
    ...persistedData,
  });

  const filter = normalizeFilters({
    ...omit(filters, 'search'),
    ...persistedFilters,
  });

  let homePageFilter = normalizeFilters(prepareFilters({ ...INIT_FILTERS, ...persistedData }));
  const selectedFacility = props.selectedFacility;
  if (isHomePage && selectedFacility) {
    homePageFilter = normalizeFilters(prepareFilters({ ...INIT_FILTERS, facilityIds: [selectedFacility] }));
  }

  const isInProgressTasks = filters.type === IN_PROGRESS_TASKS;

  const pagination = isHomePage ? HOME_PAGE_PAGINATION_CONFIG : INITIAL_PAGINATION_CONFIG;

  return {
    userId: props.context.userId,
    status: getTasksStatus(isInProgressTasks, isFirstColumn),
    pagination,
    overdue: isInProgressTasks && !isFirstColumn,
    sortBy: [
      {
        field: 'runTaskDueDate',
      },
    ],
    filter: isHomePage ? homePageFilter : filter,
    forOwner,
    search: filters?.search,
  };
};

export const getSubTaskToEdit = (initSubTasks, formValues) => {
  return initSubTasks.reduce((acc, currentSubTask) => {
    const { id, isDone } = currentSubTask;

    const subTaskValue = formValues[`subTasks_${id}`];
    const isChanged = subTaskValue !== isDone;

    if (isChanged) {
      acc.push({ id, isDone: subTaskValue });
    }
    return acc;
  }, []);
};

export const updateRunTaskMutation = (task, updatedTaskId) => (proxyCache) => ({
  runTasks: {
    ...proxyCache.runTasks,
    hits: proxyCache.runTasks.hits.map((runTask) =>
      runTask.id !== updatedTaskId
        ? runTask
        : {
            ...runTask,
            ...pick(task, ['note', 'fileId']),
          }
    ),
  },
});

export const sortByRunTasks = (sort = {}, status) => {
  if (isEmpty(sort)) {
    const order = ORDER_DESC.toUpperCase();
    const sortByName = { field: 'taskName', order: ORDER_ASC.toUpperCase() };

    return status === TASK_STATUSES.complete || status === TASK_STATUSES.incomplete
      ? [{ field: 'runTaskCompletionDate', order }, sortByName]
      : [{ field: 'runTaskDueDate', order }, sortByName];
  }
  const { column, order = ORDER_ASC } = sort;

  return [
    {
      field: column,
      order: order.toUpperCase(),
    },
  ];
};

export const TaskRequest = class {
  constructor(requests) {
    this.requests = requests;
  }

  async processTask(operation, task) {
    const isOperationExist = has(this.requests, operation);
    if (!isOperationExist) throw new Error('Please specify a task operation');

    try {
      const sendRequest = this.requests[operation];
      await sendRequest(task);
    } catch (e) {
      throw new Error('Something went during request sending');
    }

    await this.requests.refetch();
  }
};

export const getModalTitle = (modal) => {
  if (modal.isModifyingModal) {
    switch (modal.purpose) {
      case 'device':
        return 'Change Device on Existing Task or Make Copy';
      case 'person':
        return 'Change Person on Existing Task or Make Copy';
      case 'timeDue':
        return 'Change Date Due on Existing Task or Make Copy';
      default:
        return 'Modify existing Task or Make Copy';
    }
  }

  if (modal.isCopy) return 'Copy task';

  return modal.isEdit ? 'Edit task' : 'Create task';
};

export const getWeekDay = (day = 'sun') => {
  return findIndex(ALL_WEEK_DAYS, function (weekDay) {
    return weekDay.short === day;
  });
};

export const getTaskInitialValues = (modal, groups) => {
  if (isEmpty(modal.task)) return {};

  const getTaskType = () => {
    return TASK_TYPES[type] || type;
  };

  const getWeekDays = (days = {}) => {
    return days.items.map((day) => ALL_WEEK_DAYS[day].short);
  };

  const {
    id,
    type,
    name,
    description,
    deviceId,
    facilityId,
    areaId,
    assignedUserId,
    group,
    subTasks,
    logTypeId,
    repeatType,
    startOn,
    isRequiredNote,
    isRequiredSubTaskNotes,
    isRequiredImage,
    shouldCloseAutomatically,
    taskCategoryId,
    externalLink,
    schedulerTime,
    useSchedule,
  } = modal.task;

  const correctedStartOn = fromUnixTime(startOn);

  return {
    taskId: id,
    previousType: TASK_TYPES[type] || type,
    type: modal.shouldModify ? modal.taskType : getTaskType(),
    taskCategoryId,
    taskFor: deviceId ? TASK_FOR_DEVICE : TASK_FOR_AREA,
    taskBy: assignedUserId ? TASK_BY_USER : TASK_BY_GROUP,
    name,
    description,
    deviceId,
    facilityId,
    areaId,
    assignedUserId,
    group: groups && groups.length ? deserializeGroups(group) : undefined,
    subTasks,
    repeatType,
    externalLink,
    completeViaLog: Boolean(logTypeId),
    logTypeId,
    recurrenceType: TASK_TYPES[type],
    startOn: correctedStartOn,
    isRequiredNote: Boolean(isRequiredNote),
    isRequiredSubTaskNotes: Boolean(isRequiredSubTaskNotes),
    isRequiredImage: Boolean(isRequiredImage),
    shouldCloseAutomatically: Boolean(shouldCloseAutomatically),
    days: getWeekDays(JSON.parse(modal.task.days)),
    schedulerTime,
    scheduleOnlyWorkingDays: Boolean(useSchedule),
  };
};

export const retrieveTasksFromResponse = (data) => {
  if (!data) return [];
  if (has(data, 'runTasks.hits')) {
    return data?.runTasks?.hits ?? [];
  }

  return data?.runTasks ?? [];
};

export const getMappedRecurrenceFields = ({ repeatOn, recurrence, repeatEvery, startOn }) => {
  const days = {
    items: (repeatOn || []).map(getWeekDay).sort(function (x, y) {
      return x - y;
    }),
  };

  const repeatType = [TASK_TYPES.d, TASK_TYPES.n].includes(recurrence) ? 1 : Number(repeatEvery);

  const type = TASK_TYPES_REVERSE[recurrence];

  return {
    days,
    type,
    repeatType,
    startOn,
  };
};

export const getTitleByStickyFilter = ({ filter, facilitiesMap }) => {
  let dateText = ' (due ';
  switch (filter?.due) {
    case DUE_DATE_TYPES.pastNinetyDays:
      dateText += 'past 90 days at ';
      break;
    case DUE_DATE_TYPES.pastThirtyDays:
      dateText += 'past 30 days at ';
      break;
    case DUE_DATE_TYPES.thisAndNextMonth:
      dateText += 'this and next month at ';
      break;
    case DUE_DATE_TYPES.thisWeek:
      dateText += 'this week at ';
      break;
    case DUE_DATE_TYPES.yesterdayAndToday:
    default:
      dateText += 'yesterday and today at ';
      break;
  }

  if (filter?.facilityIds?.length > 0) {
    const facilities = filter.facilityIds
      .map((id) => facilitiesMap[id]?.trim())
      .filter((facilityName) => Boolean(facilityName))
      ?.join(', ');
    dateText += facilities + ')';
  } else {
    dateText += 'all facilities)';
  }

  return dateText;
};
